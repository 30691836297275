import * as React from 'react';
import { useContext } from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { rem } from 'polished';
import styled from 'styled-components';

import { Feature } from '~/components/feature';
import { PageHeading } from '~/components/page-heading';
import { getMappedHeading } from '~/core/data-mappers';
import { getLink } from '~/core/links';
import { AppContext } from '~/layout/context';
import { Seo } from 'gatsby-plugin-wpgraphql-seo';

const FinancingSection = styled(Feature)`
  padding-top: ${rem(24)};
`;

const FinancingTemplate = ({ data: { pageData } }) => {
  const { language } = useI18next();
  const { setRequestModal } = useContext(AppContext);

  const pageHeading = getMappedHeading(pageData);

  const financingFields = pageData.financingFields?.section;

  const partners = financingFields.partners.map(partner => ({
    altText: partner.logo?.altText,
    image: getImage(partner.logo?.localFile),
  }));

  return (
    <>
      <Seo post={pageData} />

      <PageHeading
        description={pageHeading.description}
        title={pageHeading.title}
        headingTag="h1"
      />

      <FinancingSection
        image={getImage(financingFields.image?.localFile)}
        imageAlt={financingFields.image?.altText}
        title={financingFields.title}
        subtitle={financingFields.subtitle}
        description={financingFields.description}
        buttonText={financingFields.buttonText}
        buttonLink={getLink('projects', language)?.url}
        buttonOnClick={() => setRequestModal(true)}
        icons={partners}
        isReverse
      />
    </>
  );
};

export const query = graphql`
  query($language: String, $slug: String, $locale: String) {
    pageData: wpPage(slug: { eq: $slug }, locale: { locale: { eq: $locale } }) {
      ...YoastSiteFragment
      ...PageHeadingFragment
      financingFields {
        section {
          buttonText
          description
          title
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 1024, placeholder: BLURRED)
              }
            }
          }
          partners {
            name
            logo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 48, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ...TranslationFragment
        }
      }
    }
  }
`;

export default FinancingTemplate;
